import React, {createContext, useState, useEffect, useRef} from 'react';
import {
    UserType,
    ClientType,
    CommentType,
    ForUserMessage,
    DataContextType,
    MessageType,
    DataProviderProps, WalletsClient
} from '../types/types';
import { socketSend, socket } from '../socket/socket';
import {forState} from "../utils/utils";
import {useIsConnectionRestored, useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
import {useParams} from "react-router-dom";
import {clientInfo, messageInfo, userInfo, wallets} from "../const/const";
import {handlePayload} from "../socket/payload";
import {handleToken} from "../socket/token";
import {handleForClient} from "../socket/client";
import {updateComments} from "../socket/comments";
import {getWalletsJetton} from "../components/client/pay/walletsJetton";



const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
    const rawAddress = useTonAddress(false);
    const { pageName } = useParams();
    const [tonConnectUI] = useTonConnectUI();

    const [setting, setSetting] = useState<boolean>(false);
    const [client, setClient] = useState<boolean>(false);
    const [authStatus, setAuthStatus] = useState<boolean>(false);
    const [userToken, setUserToken] = useState<boolean>(false);
    const [coinRate, setCoinRate] = useState<number>(1);
    const [display, setDisplay] = useState<boolean>(true);

    const [userData, setUserData] = useState<UserType>(userInfo);
    const [clientData, setClientData] = useState<ClientType>(clientInfo)
    const [walletsClient, setWalletsClient] = useState<WalletsClient>(wallets);
    const [messageData, setMessageData] = useState<MessageType>(messageInfo)
    const [meCommentsData, setMeCommentsData] = useState<CommentType[]>([]);
    const [myCommentsData, setMyCommentsData] = useState<CommentType[]>([])
    const [checkName, setCheckName] = useState<boolean | 'invalid'>(true)
    const localToken = localStorage.getItem('userToken')
    const connectionRestored = useIsConnectionRestored();


    useEffect(() => {
        const displaySize = () => {
            const shouldDisplay = window.innerWidth >= 430;
            setDisplay(shouldDisplay);
        };

        displaySize(); // Вызываем функцию для установки начального значения состояния

        window.addEventListener('resize', displaySize);

        return () => {
            window.removeEventListener('resize', displaySize);
        };
    }, []);


    useEffect(() => {

        if (connectionRestored){
            if (localToken && rawAddress) {
                setAuthStatus(true)
                handleToken(localToken, rawAddress)
            } else {
                if (rawAddress){
                    tonConnectUI.disconnect().then()
                }

                setAuthStatus(false)
            }
        }

    }, [userToken, localToken, connectionRestored, tonConnectUI]);

    useEffect(() => {

        const handleForUser = (forUser: any) => {

            const { dataUser, meComments, myComments, newComment, nameStatus }: ForUserMessage = forUser;

            setSetting(true);

            if (dataUser && dataUser !== userData) {
                setAuthStatus(true)
                setUserData(dataUser);
                getWalletsJetton(dataUser.wallet)
                    .then(data => setWalletsClient(data))
                    .catch(error => console.error("error:", error));
                forState.stop();
                forState.start();
            }

            if (myComments) {
                setMyCommentsData(prev => updateComments(prev, myComments));
            }

            if (meComments) {
                setMeCommentsData(prev => updateComments(prev, meComments));
            }

            if (newComment) {
                const commentExists = (comments: CommentType[], comment: CommentType) =>
                    comments.some(c => c._id === comment._id);

                if (newComment.uid === userData?._id) {
                    setMeCommentsData(prev => {
                        if (!commentExists(prev, newComment)) {
                            return [newComment, ...prev];
                        }
                        return prev;
                    });
                } else if (newComment.clientId === userData?._id) {
                    setMyCommentsData(prev => {
                        if (!commentExists(prev, newComment)) {
                            return [newComment, ...prev];
                        }
                        return prev;
                    });
                }
            }

            if (nameStatus) {
                setCheckName(nameStatus.checked);
            }
        };

        socket.onmessage = (event) => {
            const receivedMessage = JSON.parse(event.data);
            const { forUser, forClient, payload, token}: any = receivedMessage;

            if (payload) handlePayload(payload, tonConnectUI);
            if (token) {
                setUserToken(true);
                handleToken(token, rawAddress)
                localStorage.setItem('userToken', token);
            }
            if (forClient) handleForClient(forClient, clientData, setClient, setClientData, setSetting);
            if (forUser) {
                setAuthStatus(true);
                handleForUser(forUser);
            }
        };

    }, [clientData, meCommentsData, userData, myCommentsData, tonConnectUI, rawAddress, userToken, authStatus]);


    const connectItems = tonConnectUI.connector.wallet;
    const isInitialConnectItems = useRef(true)

    useEffect(() => {
        if (isInitialConnectItems.current) {
            isInitialConnectItems.current = false;
            return;
        }
        if (connectItems?.connectItems){
            socketSend('tonProof', connectItems as any)
                .then(() => {})
                .catch(() => {});
        }
    }, [connectItems]);

    useEffect(() => {
        if (pageName) {
            socketSend('page', pageName)
                .then(() => {})
                .catch(() => {});
        } else {
            setSetting(true);
        }
    }, [pageName]);

    return (
        <DataContext.Provider
            value={{
                setting,
                client,
                userData,
                clientData,
                walletsClient,
                messageData,
                meCommentsData,
                myCommentsData,
                checkName,
                authStatus,
                coinRate,
                display,
                setDisplay,
                setAuthStatus,
                setSetting,
                setClient,
                setUserData,
                setClientData,
                setWalletsClient,
                setCoinRate,
                setMessageData,
                setMeCommentsData,
                setMyCommentsData,
                setCheckName,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export const useDataContext = () => {
    const context = React.useContext(DataContext);
    if (context === undefined) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};
