import '../../styles/App.css'
import '../../styles/streamSetup.css'
import {copyToClipboard} from "../../utils/utils";
import {useDataContext} from "../../context/DataContext";
import {useTabContext} from "../../context/TabContext";
import React from "react";
import {useLanguage} from "../../context/LanguageContext";
import {TestAlert} from "../button-test-alert";


export const StreamSetup = () => {
    const { translations} = useLanguage()
    const { userData} = useDataContext()
    const { setActiveTab } = useTabContext();

    const token = localStorage.getItem('userToken');

    return (
        <div className={'stream-setup column-container fade-in-element gap32'}>
            <div className={'column-container'}>
                <div className={'row-container gap16'}>
                    <div className={'number'}>1</div>
                    <p>{translations.setting.streamSetup.h1}</p>
                </div>
                <div className={'user-input'} style={{paddingRight: '8px'}}>
                    <label className={'label'}>{translations.setting.streamSetup.label1}</label>
                    <input type={'text'} className={'text-input'} value={`https://donaton.tech/${userData.name}`} readOnly={true}/>
                    <button onClick={() => copyToClipboard(`https://donaton.tech/${userData.name}`)}
                            className={'button copy'}>copy
                    </button>
                </div>
                <div className={'buttons'}>
                    <button className={'button a'} onClick={() => window.location.href = `/${userData.name}`}>{translations.preview}</button>
                    <button className={'button b'} onClick={() => setActiveTab('advanced')}>{translations["edit-name"]}</button>
                </div>
            </div>
            <div className={'column-container'}>
                <div className={'row-container gap16'}>
                    <div className={'number'}>2</div>
                    <p>{translations.setting.streamSetup.h2}</p>
                </div>
                <div className={'user-input'} style={{paddingRight: '8px'}}>
                    <label className={'label'}>{translations.setting.streamSetup.label2}</label>
                        <input
                            className={'password-input'}
                            type={'password'}
                            value={`https://donaton.tech/obs/${userData._id}`}
                            readOnly={true}/>
                    <button onClick={() => copyToClipboard(`https://donaton.tech/obs/${token}`)}
                            className={'button copy'}>copy
                    </button>
                </div>
                <TestAlert/>
                {/*<ButtonSave text={translations["button-send-test"]}/>*/}
            </div>
            <div className={'column-container gap16'}>
                <div className={'row-container gap16'}>
                    <div className={'number'}>3</div>
                    <p>{translations.setting.streamSetup.h3}</p>
                </div>
                <div className={'user-input'} style={{paddingRight: '8px'}}>
                    <label className={'label'}>{translations.setting.streamSetup.label3}</label>
                    <input
                        className={'password-input'}
                        type={'password'}
                        value={`https://donaton.tech/obs/${userData._id}`}
                        readOnly={true}/>
                    <button onClick={() => copyToClipboard(`https://donaton.tech/comments/${token}`)}
                            className={'button copy'}>copy
                    </button>
                </div>
            </div>
        </div>
    )
}
