import React, {useEffect, useState} from "react";
import '../styles/linkObs.css';
import { useParams } from "react-router-dom";
import { socketSend, socket } from "../socket/socket";
import { UseLinkObs } from "../components/donations/obs/useLinkObs";
import {ForObs, ObsType, StyleDonate} from "../types/types";

export const LinkObs = () => {
    const { _id } = useParams();
    const [obsComment, setObsComment] = useState<ObsType | null>(null);
    const [styleDonate, setStyleDonate] = useState<StyleDonate>({
        fontDonate: 'Gotham Pro',
        fontMessage: 'Gotham Pro',
        colorFontDonate: '#007df5',
        colorFontMessage: '#ffffff',
        animated: 1
    })

    useEffect(() => {
        if (_id ) {
            socketSend('idObs', _id)
                .then(() => {})
                .catch((error) => {});
        }
    }, [_id]);

    useEffect(() => {
        socket.onmessage = function (event) {
            const receivedMessage = JSON.parse(event.data);
            const {obs}: any = receivedMessage;
            if (obs){
                const {comment, style} : ForObs = obs
                if (style){
                    setStyleDonate({
                        fontDonate: style.fontDonate,
                        fontMessage: style.fontMessage,
                        colorFontDonate: style.colorFontDonate,
                        colorFontMessage: style.colorFontMessage,
                        animated: style.animated
                    })
                }
                if (comment && obsComment !== comment) {

                    setObsComment({
                        nameClient: comment.nameClient,
                        amount: comment.amount,
                        text: comment.text,
                    });
                }
            }

        }
    }, [obsComment]);

    return (
        <UseLinkObs newComment={obsComment} style={styleDonate} />
    );
};
