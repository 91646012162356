import React, { useState, useEffect, useRef } from "react";
import { useTabContext } from "../context/TabContext";
import "../styles/switchSettings.css";
import {useLanguage} from "../context/LanguageContext";

export const SwitchSettings: React.FC = () => {
    const { activeTab, setActiveTab } = useTabContext();
    const { translations} = useLanguage()
    const [borderStyle, setBorderStyle] = useState({ width: '104px', left: '0' });
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const select = document.getElementById(`${activeTab}`);
        const streamSetup = document.getElementById(`streamSetup`);
        const design = document.getElementById(`design`);


        if (divRef.current && select && streamSetup && design) {

            const width = select.offsetWidth;

            switch (activeTab) {
                case 'streamSetup':
                    setBorderStyle({ width: `${width}px`, left: '0' });
                    break;
                case 'design':
                    setBorderStyle({ width: `${width}px`, left: `${streamSetup.offsetWidth + 24}px` });
                    break;
                case 'advanced':
                    setBorderStyle({ width: `${width}px`, left: `${streamSetup.offsetWidth + design.offsetWidth + 48}px` });
                    break;
                default:
                    break;
            }
        }
    }, [activeTab, translations]);

    return (
        <div className="tabs">
            <div
                id="streamSetup"
                ref={divRef}
                className={`tab ${activeTab === 'streamSetup' ? 'active' : ''}`}
                onClick={() => setActiveTab('streamSetup')}
            >
                <h3>{translations.setting.streamSetup.title}</h3>
            </div>
            <div
                id="design"
                className={`tab ${activeTab === 'design' ? 'active' : ''}`}
                onClick={() => setActiveTab('design')}
            >
                <h3>{translations.setting.design.title}</h3>
            </div>
            <div
                id="advanced"
                className={`tab ${activeTab === 'advanced' ? 'active' : ''}`}
                onClick={() => setActiveTab('advanced')}
            >
                <h3>{translations.setting.advanced.title}</h3>
            </div>
            <div
                id="borderTaps"
                className="border-taps"
                style={borderStyle}
            ></div>
        </div>
    );
};
