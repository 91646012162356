import '../../../styles/linkObs.css';
import React, { useEffect, useState } from "react";
import {ObsType, StyleDonate} from "../../../types/types";
import {forState} from "../../../utils/utils";
import Lottie from "lottie-react";
import {gifs} from "../../../const/gifs";


export const UseLinkObs = ({ newComment, style }:  ObsType | StyleDonate | null | any) => {

    const [comment, setComment] = useState<ObsType>({
        nameClient: '',
        amount: '',
        text: ''
    });

    useEffect(() => {
        document.body.style.background = 'none';
        document.documentElement.style.background = 'none'

        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    useEffect(() => {

        const styleName = document.getElementById('name-donate')
        if (styleName){
            styleName.style.fontFamily = `${style.fontDonate}, sans-serif`
            styleName.style.color = style.colorFontDonate
        }

        const styleValue = document.getElementById('value-donate')
        if (styleValue){
            styleValue.style.fontFamily = `${style.fontDonate}, sans-serif`
            styleValue.style.color = style.colorFontDonate
        }
        const styleMessage = document.getElementById('message-donate')
        if (styleMessage){
            styleMessage.style.fontFamily = `${style.fontMessage}, sans-serif`
            styleMessage.style.color = style.colorFontMessage
        }

    }, [style]);

    const [showComment, setShowComment] = useState(false);
    forState.start()

    useEffect(() => {
        if (newComment) {
            setComment({
                nameClient: newComment.nameClient,
                amount: newComment.amount + ' ton',
                text: newComment.text
            });

            if ('speechSynthesis' in window) {
                const speech = new SpeechSynthesisUtterance();

                speech.text = newComment.text;
                speech.lang = 'ru-RU';
                speech.rate = 0.9;
                speech.pitch = 1;
                window.speechSynthesis.speak(speech);
                console.log('voice')
            } else {
                console.log("Ваш браузер не поддерживает синтез речи.");
            }

            setShowComment(true);
            const timer = setTimeout(() => {
                setComment({
                    nameClient: '',
                    amount: '',
                    text: '',
                });
                setShowComment(false);
                console.log(showComment, 'showComment');
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [newComment]);

    return (
        <div id="obsLink" className="color-obs stickers-input fix-stroke">
            <div className="sticker">
                {showComment &&
                    <Lottie
                        id={'animated'}
                        style={{width: 80, height: 80, alignSelf: 'center'}}
                        animationData={gifs[style.animated].animationData}
                        loop={true}
                    />
                }
            </div>
            <div className={'title'}>
                <div id="name-donate" className="name-obs">{comment.nameClient}</div>
                <div id="value-donate" className="name-obs">{comment.amount}</div>
            </div>
            <div id="message-donate" className="text-obs">{comment.text}</div>
        </div>
    );
};
