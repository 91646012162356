import {Address, beginCell, toNano} from "@ton/core";

export const bodyTx = (walletUser: string, walletRes: string, amount: string) => {
    const body = beginCell()
        .storeUint(0xf8a7ea5, 32)
        .storeUint(0, 64)
        .storeCoins(toNano(amount))
        .storeAddress(Address.parse(walletUser))
        .storeAddress(Address.parse(walletRes))
        .storeUint(0, 2)
        .storeCoins(toNano("0.05"))
        .storeUint(0,1)
        .endCell();
    return body.toBoc().toString("base64");
}
