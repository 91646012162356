import React from "react";
import "../../styles/design.css";
import {InputGif} from "./inputs/inputGif";
import {InputsFonts} from "./inputs/inputsFonts";
import {ButtonSave} from "../button-save";
import {useLanguage} from "../../context/LanguageContext";
import {TestAlert} from "../button-test-alert";

export const Design = () => {

    const {translations} = useLanguage()

    return (
        <div className={'column-container fade-in-element design'}>
            <InputGif />
            <InputsFonts />
            <div className={'buttons'} style={{flexDirection: 'column', width: '100%'}}>
                <TestAlert/>
                {/*<ButtonSave text={translations["button-send-test"]}/>*/}
                <ButtonSave color={'#323232'} text={translations["save-design"]} hover={true}/>
            </div>

        </div>
    );
};
