import {useDataContext} from "../../../context/DataContext";
import React from "react";
import {step} from "../../../const/const";
import plus from "../../../img/ic-plus.svg";
import {amountFormatter} from "./replaceInput";

interface ChangeProps {
    onChange: (e: string) => void;
}

export const Plus = ({ onChange }: ChangeProps) => {
    const {messageData, setMessageData} = useDataContext()

    const plusValue = () => {
        const value = (messageData.amount + step).toFixed(3)
        onChange(amountFormatter(value))
        setMessageData({...messageData, amount: parseFloat(value)})
    };

    return (
        <div
            id={'plus'}
            className={'icon-input plus'}
            onClick={() => plusValue()}>
            <img
                className={'icon'}
                src={plus}
                alt="plus"
            />
        </div>
    )
}
