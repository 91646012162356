import React, { createContext, useState, useContext, ReactNode } from "react";
import en from "../language/en.json";

export interface Translations {
    "donation-settings": string;
    "main-title": string;
    "main-p": string;
    "main-button": string;
    setting: {
        title: string;
        streamSetup: {
            title: string;
            h1: string;
            label1: string;
            h2: string;
            label2: string;
            h3: string;
            label3: string;
        };
        design: {
            title: string;
            label1: string;
            label2: string;
        };
        advanced: {
            title: string;
            label1: {
                "your-nickname": string;
                "invalid-name": string;
                "nickname-taken": string;
            };
            label2: string;
            Description: string
        };
    };
    donations: {
        "button-me": string;
        "button-my": string;
        title: string;
        "text-me": string;
        "text-my": string;
    };
    "button-save": string;
    "button-send-test": string,
    "edit-name": string,
    "preview": string,
    "save-design": string,
    "client": {
        "status": {
            "online": string;
            "offline": string;
        },
        "min-amount": string,
        "name": string,
        "message": string,
        "label": string,
        "button": string,
        "show-more": string,
        "show-less": string
    }
}

type Language = "en" | "ru";

interface LanguageContextProps {
    language: Language;
    changeLanguage: (lang: Language) => void;
    translations: Translations;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [language, setLanguage] = useState<Language>("en");
    const [translations, setTranslations] = useState<Translations>(en);

    const changeLanguage = async (lang: Language) => {
        if (lang === language) return;

        try {

            const loadedTranslations = await import(`../language/${lang}.json`);
            setLanguage(lang);
            setTranslations(loadedTranslations.default);
        } catch (error) {
            console.error(`Failed to load translations for language: ${lang}`, error);
        }
    };

    const value = {
        language,
        changeLanguage,
        translations,
    };

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = (): LanguageContextProps => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error("useLanguage must be used within a LanguageProvider");
    }
    return context;
};
