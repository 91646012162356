import Search from "../img/srch.json";
import Lottie from "lottie-react";
import React from "react";
import '../styles/erroePage.css'

export const ErrorPage = () => {
    return (
        <div className="error-page">
            <Lottie
                animationData={Search}
                loop={true}
                className='search'
            />
            <h1 style={{textAlign: 'center'}}>Streamer not found</h1>
        </div>
    )
}
