import React, {useEffect, useRef, useState} from "react";
import {useDataContext} from "../../../context/DataContext";
import {getTonPrice, replaceAmount, useDebounce} from "../../../utils/utils";
import {Minus} from "./minus";
import {Plus} from "./plus";
import {ChangeCurrency, ChangeCurrencyProps} from "./changeСurrency";
import {dogs, not, mrdn} from "../../../const/const";
import {Currency} from "../../../types/types";
import {amountFormatter} from "./replaceInput";

export const InputDonate = ({ onCurrencyChange }: ChangeCurrencyProps) => {
    const {messageData, setMessageData, clientData, coinRate, setCoinRate} = useDataContext();
    const inputDonateRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const [spanValue, setSpanValue] = useState<string>('');
    const debouncedDonate = useDebounce(messageData.amount, 800);
    const [selectedCurrency, setSelectedCurrency] = useState<string>('TON');

    const handleCurrencyChange = (currency: string) => {
        onCurrencyChange(currency);
        setSelectedCurrency(currency);
    };

    const focusInput = () => {
        const inputElement = document.getElementById('minDonate') as HTMLInputElement;
        if (inputElement){
            inputElement.focus();
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement> ) => {
        let inputElement = e.target.value;
        setSpanValue(amountFormatter(inputElement));
        setMessageData({...messageData, amount: parseFloat(amountFormatter(inputElement))});
    }

    useEffect(() => {
        const minDonateInSelectedCurrency = replaceAmount(clientData.minDonate / coinRate);

        if (isNaN(debouncedDonate) || debouncedDonate < minDonateInSelectedCurrency) {
            setMessageData({ ...messageData, amount: minDonateInSelectedCurrency });
            setSpanValue(amountFormatter(minDonateInSelectedCurrency.toString()))
        }
    }, [debouncedDonate, clientData.minDonate, selectedCurrency, coinRate]);

    useEffect(() => {
        const fetchPrice = async () => {
            const tonPrice = await getTonPrice();
            if (tonPrice !== null) {
                const priceMapping = {
                    mrdn: tonPrice[mrdn]?.prices?.USD,
                    not: tonPrice[not]?.prices?.USD,
                    dogs: tonPrice[dogs]?.prices?.USD,
                    TON: tonPrice.TON?.prices?.USD,
                    usdt: 1
                };

                const currentPrice = priceMapping[selectedCurrency as Currency];
                if (currentPrice !== undefined) {
                    setCoinRate(currentPrice);
                }
            }
        };
        fetchPrice().catch(console.error);
        const interval = setInterval(fetchPrice, 60000);
        return () => clearInterval(interval);
    }, [selectedCurrency]);

    useEffect(() => {
        const span = document.getElementById('span') as HTMLSpanElement;
        const amount = document.getElementById('minDonate') as HTMLInputElement;
        if (spanRef && inputDonateRef){
            amount.style.width = `${span.offsetWidth + 11}px`
        }
    }, [spanValue ]);

    return (
        <div className="amount-input" >
            <div className={'amount-inputs'} onClick={() => focusInput()}>
                <Minus onChange={setSpanValue}/>
                <div className="amount">
                    <div id={'text-donate'} className={'text-donate'}>
                        <span
                            id={'span'}
                            ref={spanRef}
                            className={'value-donate'}
                            style={{
                                position: 'absolute',
                                visibility: 'hidden',
                                whiteSpace: 'pre',
                                width: 'auto'
                            }}
                        >{spanValue}</span>

                        <input id="minDonate"
                               ref={inputDonateRef}
                               type={'text'}
                               className="value-donate"
                               value={spanValue}
                               maxLength={8}
                               pattern="[0-9]*"
                               inputMode="decimal"
                               onChange={onChange}
                               style={{minWidth: '14px', paddingTop: 0}}
                        />
                        <h5 style={{width: 'auto'}} id={'h5'}>{selectedCurrency}</h5>
                    </div>


                    <label className="amount-label"
                           htmlFor="minDonate">~ {replaceAmount(coinRate * messageData.amount)}$</label>
                </div>
                <Plus onChange={setSpanValue}/>
            </div>
            <ChangeCurrency onCurrencyChange={handleCurrencyChange}/>
        </div>
    );
};
