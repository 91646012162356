import { url } from "../../const/const";
import React, { useEffect, useState } from "react";
import { useDataContext } from "../../context/DataContext";
import emoji from "../../img/ic-duck.svg";
import { useLanguage } from "../../context/LanguageContext";

export const Info = () => {
    const { clientData } = useDataContext();
    const { translations } = useLanguage();
    const [imgExists, setImgExists] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const shortDescription = clientData.description && clientData.description.length > 168
        ? clientData.description.substring(0, 168) + "..."
        : clientData.description;

    useEffect(() => {
        if (clientData.image) {
            const img = new Image();
            img.src = `https://donaton.tech/${clientData.image}`;
            img.onload = () => setImgExists(true);
            img.onerror = () => setImgExists(false);
        } else {
            setImgExists(false);
        }
    }, [clientData.image]);

    return (
        <div className={'client-info column-container'}>
            <div className="photo-user">
                {imgExists && (
                    <img
                        src={`${url}/${clientData.image}?${new Date().getTime()}`}
                        onError={(e) => {
                            e.currentTarget.src = emoji;
                            e.currentTarget.style.width = "48px";
                            e.currentTarget.style.height = "48px";
                            e.currentTarget.style.padding = "14px";
                        }}
                        className="img-st"
                        alt=""
                    />
                )}
                {!imgExists && (
                    <img src={emoji} alt={''}/>
                )}
            </div>
            <div className="name-st">
                {clientData.name}
            </div>
            <div
                className="live"
                style={{ background: clientData.verified ? "rgba(219, 254, 1, 0.08)" : "#323232" }}
            >
                <div
                    className="live-ellipse"
                    style={{ background: clientData.verified ? "rgba(210, 240, 25, 1)" : "rgb(132,132,132)" }}
                ></div>
                <div
                    className="live-text"
                    style={{ color: clientData.verified ? "rgba(210, 240, 25, 1)" : "rgb(132,132,132)" }}
                >
                    {clientData.verified
                        ? translations.client.status.online
                        : translations.client.status.offline}
                </div>
            </div>
            {clientData.description && (
                <div className="client-description">
                    <p className="description-text">
                        {isExpanded ? clientData.description : shortDescription}
                        {!isExpanded && clientData.description && clientData.description.length > 168 && (
                            <>
                                <button className="more-button" onClick={toggleExpanded}>
                                    {translations.client["show-more"]}
                                </button>
                            </>
                        )}
                    </p>
                    {isExpanded && (
                        <button className="more-button" onClick={toggleExpanded}>
                            {translations.client["show-less"]}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};
