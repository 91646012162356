import lang from "../../language/en.json";
import React, {ChangeEvent, useEffect} from "react";
import {useDataContext} from "../../context/DataContext";
import {socketSend} from "../../socket/socket";
import {useDebounce} from "../../utils/utils";
import {useLanguage} from "../../context/LanguageContext";

export const InputsNameDonate = () => {
    const {userData, setUserData, checkName} = useDataContext()
    const { translations} = useLanguage()
    const debouncedName = useDebounce(userData.name, 500);

    const nameChange = (field: 'name') => (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const regex = /^[a-zA-Z0-9.,]*$/;
        if (regex.test(inputValue)) {
            setUserData({...userData, [field]: inputValue})
        }
    };

    const handleChange = (field: 'minDonate') => (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const normalizedValue = inputValue.replace(',', '.');
        const parsedValue = normalizedValue === '' ? '' : parseFloat(normalizedValue);
        setUserData({...userData, [field]: parsedValue})
    };

    const descriptionChange = (field: 'description') => (event: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = event.target.value;
        setUserData({...userData, [field]: inputValue})
    }

    useEffect(() => {
        if (userData.name !== 'Name' && debouncedName !== 'Name')
        socketSend('name', { changeName: debouncedName, wallet: userData.wallet })
            .then(() => {})
            .catch(() => {
            });

    }, [debouncedName, userData.wallet]);

    return (
        <div className={'inputs user-name'}>
            <div className={'user-input name'}>
                <label
                    id={'label-name'}
                    className="label"
                    htmlFor="name"
                    style={{
                        color: checkName === 'invalid' ? '#ff0000' : (checkName ? '#D2F019' : '#ff0000')
                    }}>
                    {checkName === 'invalid' ? translations.setting.advanced.label1["invalid-name"] : (checkName ? translations.setting.advanced.label1["your-nickname"] : translations.setting.advanced.label1["nickname-taken"])}
                </label>
                <input
                    id="name"
                    className="text-input"
                    type="text"
                    autoComplete={'off'}
                    value={userData.name}
                    onChange={nameChange("name")}
                    maxLength={35}
                />
            </div>
            <div className={'user-input donate'}>
                <label className="label" htmlFor="minDonate">
                    {translations.setting.advanced.label2}
                </label>
                <input
                    id="minDonate"
                    className="text-input"
                    pattern="[0-9]*"
                    inputMode="decimal"
                    autoComplete={'off'}
                    type="number"
                    value={userData.minDonate === '' ? '' : userData.minDonate}
                    onChange={handleChange("minDonate")}
                    placeholder="0.00"
                />
            </div>
            <div className={'user-input description'}>
                <label className="label" htmlFor="description">{translations.setting.advanced.Description}</label>
                <textarea
                    id="description"
                    className="text-input"
                    autoComplete={'off'}
                    value={userData.description}
                    onChange={descriptionChange("description")}
                    // maxLength={200}
                />

            </div>
        </div>
    )
}
