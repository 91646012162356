import './footer.css';
import tg from '../../img/ic-tg.svg'

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="science">
                © Donaton, 2024
            </div>
            <div className={'tg-button'} onClick={() => window.location.href='https://t.me/donatonEN'}>
                <img src={tg} alt={''}/>
            </div>
        </footer>
    );
};
