import { useDebounce } from "../../utils/utils";
import React, {useEffect, useRef, useState} from "react";
import {useDataContext} from "../../context/DataContext";
import {useLanguage} from "../../context/LanguageContext";

export const InputName = () => {
    const {messageData, setMessageData} = useDataContext()
    const {translations} =useLanguage()
    const [nameClient, setNameClient] = useState<string>(localStorage.getItem('nameClient') || '');
    const debouncedName = useDebounce(nameClient, 500);

    const inputRef = useRef<HTMLInputElement>(null);
    const handleClick = () => {
        inputRef.current?.select();
    };

    const change = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;
        setNameClient(inputValue)
        localStorage.setItem('nameClient', inputValue)

    }

    useEffect(() => {
        setMessageData({...messageData, nameClient: nameClient});
    }, [debouncedName]);

    return (
        <div className="name-input">
            <input id="nameInput"
                   autoComplete="off"
                   className="name-cl"
                   required
                   value={nameClient}
                   onClick={handleClick}
                   onChange={change}
                   ref={inputRef}
            />
            <label htmlFor={'nameInput'} className={'label'}>{translations.client.name}</label>
        </div>
    )
}
