import React from 'react';
import {socketSend} from "../socket/socket";
import {dogs, mrdn, not} from "../const/const";
import {toNano} from "@ton/core";

export const useDebounce = <S,>(value: S, delay: number = 300) => {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        const handler: NodeJS.Timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [delay, value]);

    return debouncedValue;
}


interface ForState {
    start: () => void;
    stop: () => void;
}

export const forState: ForState = (() => {
    let intervalId: number | undefined;

    const start = () => {
        if (intervalId === undefined) {
            intervalId = window.setInterval(() => socketSend('compound', true), 2000);
        }
    };
    const stop = () => {
        if (intervalId !== undefined) {
            clearInterval(intervalId);
            intervalId = undefined;
            // console.log('Interval stopped');
        }
    };

    return {
        start,
        stop
    };
})();


export const copyToClipboard = (link: string) => {
    navigator.clipboard.writeText(link).then(() => {
        console.log('Текст скопирован в буфер обмена');
    }).catch((e) => {
        console.error('Не удалось скопировать текст: ', e);
    });
};

export const getTonPrice = async (): Promise<any> => {

    const url = `https://tonapi.io/v2/rates?tokens=ton,${mrdn},${not},${dogs}&currencies=usd`;

    try {
        const response = await fetch(url);

        const data = await response.json();
        return data.rates
    } catch (e) {
        return null;
    }
};

export const dateC = (date: Date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

export const replaceAmount = (amount: number) => {
    return parseFloat(amount.toFixed(3));
}

export const replace = (amount: number, decimals: number) => {
    // Умножаем значение на 10^decimals и округляем
    const multiplier = Math.pow(10, decimals);
    return Math.round(amount * multiplier) / multiplier;
}

// Пример использования replaceAmount с toNano
export const convertToCoin = (amount: number, coin: string) => {
    if (coin.toUpperCase() === 'USDT') {
        // Для USDT (6 знаков после запятой)
        return replace(amount, 6);
    } else {
        // Для других монет (например, 9 знаков для TON)
        return toNano(replace(amount, 9).toString()); // Конвертация через toNano для TON
    }
}






