import '../styles/main.css'
import React from "react";
import {useTonConnectModal} from "@tonconnect/ui-react";
import {useLanguage} from "../context/LanguageContext";
export const Main = () => {
    const { open } = useTonConnectModal();
    const {translations} = useLanguage()
    return(
        <div className={'main'}>
            <div className={'img-wrapper'}>
                <span className={'gradient'}/>
                <img className={'img-main'} src={'/assets/mainImg.png'} alt={'img-main'}/>
                <div className={'text-main'}>
                    <h1>{translations["main-title"]}</h1>
                    <p>{translations["main-p"]}</p>
                    <button onClick={() => open()}
                            className={'button'}
                    style={{width: 'auto', backgroundColor: '#D2F019', color: '#323232', marginTop: '24px'}}>{translations["main-button"]}</button>
                </div>
            </div>
        </div>
    )
}
