import {
    ClientType,
    CommentType,
    LoadComment, LoadMoreComments,
    MessageType,
    NameCheck,
    UserAuth,
    UserType,
    UserUpdate
} from "../types/types";
import { Wallet } from "../types/typeProof";

let isConnect = false;
// const url: string = "ws://localhost:4000"
const url: string = "wss://donaton.tech/ws"
export let socket = new WebSocket(url);

type PendingPromise = {
    resolve: () => void;
    reject: (error?: any) => void;
};

let pendingPromises: PendingPromise[] = [];

const setupSocketHandlers = (ws: WebSocket) => {
    ws.onopen = function (event: Event) {
        isConnect = true;
        console.log("Соединение установлено");
        resolvePendingPromises();

    };

    ws.onerror = function (error: Event) {
        console.log("Произошла ошибка в WebSocket:");
        isConnect = false;
        reconnect();
    };

    ws.onclose = function (event: CloseEvent) {
        console.log("Соединение закрыто");
        isConnect = false;
        reconnect();
    };
};

const reconnect = () => {
    let timer = setInterval(() => {
        if (isConnect) {
            clearInterval(timer);
            return;
        }
        console.log('Попытка переподключения...');
        connect();
    }, 1000);
};

const connect = () => {
    if (socket.readyState !== WebSocket.CLOSED && socket.readyState !== WebSocket.CLOSING) {
        console.log("Попытка подключения в неправильном состоянии WebSocket:");
        return;
    }

    socket = new WebSocket(url);
    setupSocketHandlers(socket);
};

setupSocketHandlers(socket);

const waitForConnection = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (socket.readyState === WebSocket.OPEN) {
            resolve();
        } else {
            pendingPromises.push({ resolve, reject });
        }
    });
};

const resolvePendingPromises = () => {
    pendingPromises.forEach(({ resolve }) => resolve());
    pendingPromises = [];
};

export const socketSend = async (
    key: string,
    text: string | boolean | UserType | ClientType | CommentType | MessageType | NameCheck | UserUpdate | UserAuth | Wallet | LoadComment | LoadMoreComments
): Promise<void> => {
    return waitForConnection()
        .then(() => {
            const data: Record<string, any> = {};
            data[key] = text;
            if (socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify(data));
            } else {
                console.error("Ошибка отправки данных: соединение не установлено.");
                return Promise.reject(new Error("Соединение не установлено"));
            }
        });
};
