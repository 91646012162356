import logo from '../img/logoHeader.svg';
import '../styles/header.css';
import {useTonConnectModal, useTonAddress, useTonConnectUI, useIsConnectionRestored} from "@tonconnect/ui-react";
import React, {FC, useEffect, useRef, useState} from "react";
import icon from '../img/ic-arrow.svg';
import load from '../img/ic-loader.svg'
import {useDataContext} from "../context/DataContext";
import {socketSend} from "../socket/socket";
import {useLanguage} from "../context/LanguageContext";
import enFlag from "../img/ic-en.svg";
import ruFlag from "../img/ic-ru.svg";

export const Header: FC = () => {
    const { display} = useDataContext()

    const { open } = useTonConnectModal();
    const { changeLanguage } = useLanguage();

    const lang = [
        { text: 'en', img: enFlag },
        { text: 'ru', img: ruFlag }
    ];

    const [openLang, setOpenLang] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(lang[0]);

    const [tonConnectUI] = useTonConnectUI();
    const [viewConnection, setViewConnection] = React.useState<boolean>(false);
    const { setAuthStatus, setClient, } = useDataContext();
    const viewConnectionRef = useRef<HTMLDivElement | null>(null);
    const langRef = useRef<HTMLDivElement | null>(null);

    const address = useTonAddress();
    const rawAddress = useTonAddress(false);
    const connectionRestored = useIsConnectionRestored();

    const disconnect = async () => {
        if (address) {
            await tonConnectUI.disconnect();
            [setViewConnection, setAuthStatus, setClient].forEach(fn => fn(false));
        }
    }

    const handleWalletClick = async () => {
        address ? setViewConnection(!viewConnection) : open();
    };

    const handleLanguageChange = (language: { text: string, img: string }) => {
        setSelectedLanguage(language);
        changeLanguage(language.text as "en" | "ru");
        localStorage.setItem('selectedLanguage', language.text);
        setOpenLang(false);
    };

    useEffect(() => {
        !connectionRestored
            ? tonConnectUI.setConnectRequestParameters({ state: 'loading' })
            : !rawAddress && socketSend('connect', false).catch(() => {});

        const updateWalletText = () => {
            const element = document.getElementById('textConnect');
            const langText = document.getElementById('lang-text')
            if (!element) return;

            const sizeText = window.innerWidth < 430 ? 2 : 3;
            const originalText = address || (!display ? 'connect' : 'connect wallet');
            element.innerText = originalText;

            if (langText) {
                langText.style.display = !display ? 'none' : 'block';
            }

            while (element.scrollWidth > element.offsetWidth && originalText.length > sizeText * 2) {
                const startText = originalText.slice(0, sizeText);
                const endText = originalText.slice(-sizeText);
                element.innerText = `${startText}...${endText}`;
            }
        };

        updateWalletText();
    }, [address, connectionRestored, display]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (viewConnectionRef.current && !viewConnectionRef.current.contains(event.target as Node)) {
                setViewConnection(false);
            }
            if (langRef.current && !langRef.current.contains(event.target as Node)) {
                setOpenLang(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="row-container"
                style={{width: display? '':'calc(100% - 32px)'}}
        >
            <div className="logo" onClick={() => window.location.href = '/'}>
                <img src={logo} alt="logo"/>
            </div>
            <div className="buttons">
                <div className="language" onClick={() => setOpenLang(!openLang)}>
                    <div className="flag">
                        <img src={selectedLanguage.img} alt={selectedLanguage.text}/>
                        <h3 id={'lang-text'}>{selectedLanguage.text}</h3>
                    </div>
                    {openLang && (
                        <div className="languages" ref={langRef}>
                            {lang.map(({text, img}) => (
                                <span
                                    className="span"
                                    key={text}
                                    onClick={() => handleLanguageChange({text, img})}
                                >
                                <img src={img} alt={text}/>
                                    {display && (
                                        <h3>{text}</h3>
                                    )}
                            </span>
                            ))}
                        </div>
                    )}
                </div>
                <div className={'row-container ton-connect'} onClick={handleWalletClick}>

                    {!connectionRestored && (<img src={load} alt={'.'} className={'loader-icon'}/>)}

                    <div className="text-connect"
                         id="textConnect"
                         style={{display: !connectionRestored ? 'none' : 'inline'}}>
                    </div>
                    <img className="img-connect" src={icon} alt="" style={{display: address ? 'inline' : 'none'}}/>

                    {viewConnection && (
                        <div className="view-connection" ref={viewConnectionRef}>
                            <span className={'select-wallet'} onClick={() => disconnect()}>Disconnect</span>
                            <a className="select-wallet" href="/">Settings</a>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};
