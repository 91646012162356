import '../styles/button-save.css'
import React, {useEffect, useRef, useState} from "react";
import {socketSend} from "../socket/socket";
import {useDataContext} from "../context/DataContext";
import {UserUpdate} from "../types/types";

export const ButtonSave = ({backgroundColor, color, text, hover}: {backgroundColor?: string, color?: string, text: string, hover?: boolean}) => {

    const { userData } = useDataContext();
    const token = localStorage.getItem('userToken');
    const [formData, setFormData] = useState<UserUpdate>({
        userData: userData,
        token: ''
    });
    const isInitialMount = useRef(true);
    const buttonDoneRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (userData && !!token) {
            setFormData({
                userData: userData,
                token: token
            });
            isInitialMount.current = true;
        }
    }, [userData, token]);

    useEffect(() => {
        if (userData && token) {
            setFormData(prev => ({
                ...prev,
                userData: {
                    ...userData
                }
            }));
        }
    }, [userData, token]);

    const done = () => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            socketSend('settings', formData)
                .then(() => {})
                .catch((error) => {});
            return;
        }
    };
    return (
        <button id={'buttonDone'}
                style={{color: color? color : '' }}
                className={`button ${hover ? 'button-save-hover' : ''}` }
                onClick={done} ref={buttonDoneRef}>
            {text}
        </button>
    )
}
