import React from "react";
import '../../styles/advanced.css';
import {useDataContext} from "../../context/DataContext";
import {ImgUpload} from "./imgUpload";
import {InputsNameDonate} from "./inputs";
import {ButtonSave} from "../button-save";
import {useLanguage} from "../../context/LanguageContext";

export const Advanced = () => {
    const {userData, setUserData} = useDataContext()
    const {translations} = useLanguage()

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.checked;
        setUserData({...userData, verified: inputValue})
    };

    return (
        <div className={'column-container fade-in-element'}>
            <ImgUpload />
            <InputsNameDonate />
            <div className={'live-setting'}>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={userData.verified}
                        onChange={handleSwitchChange}
                    />
                    <span className="slider"></span>
                </label>
                <h3 style={{color: userData.verified? '#D2F019' : '#848484', fontWeight: '800'}}>
                    {userData.verified ? `${translations.client.status.online}` : `${translations.client.status.offline}`}
                </h3>
            </div>
            <div style={{width: '100%', paddingTop: '24px'}}
            >
                <ButtonSave text={translations["button-save"]}/>
            </div>

        </div>
    );
};
