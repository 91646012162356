import React, {useEffect, useState} from "react";
import '../../styles/client.css';
import '../../styles/index.css'
import {useDataContext} from "../../context/DataContext";
import {InputDonate} from "./buttonsInput/inputDonate";
import {InputName} from "./inputName";
import {InputMessage} from "./inputMessage";
import {ButtonPay} from "./pay/button";
import {Info} from "./info";
import {useLanguage} from "../../context/LanguageContext";

export const Client = () => {

    const {clientData, userData, messageData,setMessageData} = useDataContext();
    const {translations} = useLanguage()
    const [selectedCurrency, setSelectedCurrency] = useState<string>('TON');

    const handleCurrencyChange = (currency: string) => {
        setSelectedCurrency(currency);
    };


    useEffect(() => {
        setMessageData({...messageData,
            uid: clientData._id,
            clientId: userData._id,
            clientWallet: userData.wallet,
            amount: clientData.minDonate,
            name: clientData.name,
            wallet: clientData.wallet,
        })
    }, [clientData, userData]);

    return (
        <div className={'column-container client'}>
            <Info />
            <div className={'inputs-client'}>
                <InputDonate onCurrencyChange={handleCurrencyChange}/>
                <InputName/>
                <InputMessage/>
                <div className={'ic-voice'}>
                    <div className={'icons-voice'}>
                        <div className={'icon-voice1'}/>
                        <div className={'icon-voice2'}/>
                        <div className={'icon-voice3'}/>
                    </div>
                    {translations.client.label}
                </div>
            </div>
            <ButtonPay selectCurrency={selectedCurrency}/>
        </div>
    );
};
