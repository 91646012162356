import {ClientType, MessageType, UserType, WalletsClient} from "../types/types";

export const userInfo: UserType = {
    _id: '0',
    wallet: "0",
    name: "Name",
    balance: 0,
    minDonate: '',
    socialLink: 'social link',
    fontDonate: 'Gotham Pro',
    colorFontDonate: '#007df5',
    fontMessage: 'Arial',
    colorFontMessage: '#ffffff',
    colorLeft: "#00b2ff",
    colorRight: "#a071ff",
    animated: 1,
    verified: false,
    image: '',
    description: '',

}
export const clientInfo: ClientType = {
    _id : '',
    wallet: '',
    name: '',
    minDonate: 0,
    socialLink: '',
    colorLeft: '',
    colorRight: '',
    verified: false,
    image: '',
    description: '',
}

export const messageInfo: MessageType = {
    uid: '',
    clientWallet: '',
    clientId: '',
    nameClient: 'Anonymous',
    name: '',
    wallet: '',
    amount: 0,
    text: '',
    boc: ''
}

// export const url = 'http://localhost:4000'
// export const url = 'file:///Users/karo/Desktop/donateTon/server'
export const url = 'https://donaton.tech'

export const step: number = 1

export const wallets: WalletsClient = {
    usdt: '',
    not: '',
    dogs: '',
    mrdn: ''
}

export const mrdn = 'EQCymLRXp1QYxZKek4CTInckB1ey5TkyAJQpPAlNetiO54Vt'
export const not = 'EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT'
export const dogs = 'EQCvxJy4eG8hyHBFsZ7eePxrRsUQSFE_jpptRAYBmcG_DOGS'
