import {useDataContext} from "../../../context/DataContext";
import minus from "../../../img/ic-minus.svg";
import React from "react";
import {step} from "../../../const/const";
import {amountFormatter} from "./replaceInput";

interface ChangeProps {
    onChange: (e: string) => void;
}

export const Minus = ({ onChange }: ChangeProps) => {
    const {messageData, setMessageData, clientData, coinRate} = useDataContext()

    const minusValue = () => {
        const newValue= (messageData.amount - step).toFixed(3)
        const value = parseFloat(newValue) < 0 ? 0 : parseFloat(newValue);
        onChange(amountFormatter(newValue))
        setMessageData({...messageData, amount: value})
    };

    return (
        <div
            id={'minus'}
            className={'icon-input minus'}
            style={{
                backgroundColor: messageData.amount * coinRate <= clientData.minDonate ? '#686868' : '#D2F019',
                cursor: messageData.amount * coinRate <= clientData.minDonate ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
                if (messageData.amount * coinRate > clientData.minDonate) {
                    minusValue();
                }
            }}
        >
            <img
                className={'icon'}
                src={minus}
                alt="minus"
            />
        </div>
    )
}
