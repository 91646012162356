
export const encodeColon = (input: string): string => {
    return input.replace(/:/g, '%3A');
};

export const getWalletsJetton = async (wallet: string) => {
    let jettonWallet = { usdt: '', mrdn: '', not: '', dogs: ''};
    try {
        const encodedWallet = encodeColon(wallet);
        const url = `https://tonapi.io/v2/accounts/${encodedWallet}/jettons?currencies=ton,usd,mrdn,not,dogs&supported_extensions=custom_payload`;
        const response = await fetch(url);
        const data = await response.json();
        for (const item of data.balances) {
            if (item.jetton.name === 'Meridian') {
                jettonWallet.mrdn = item.wallet_address.address;
            }
            if (item.jetton.name === 'Tether USD') {
                jettonWallet.usdt = item.wallet_address.address;
            }
            if (item.jetton.name === 'Dogs') {
                jettonWallet.dogs = item.wallet_address.address;
            }
            if (item.jetton.name === 'Notcoin') {
                jettonWallet.not = item.wallet_address.address;
            }
        }
        return jettonWallet;
    } catch (error) {
        console.error("Ошибка jettons:", error);
        throw error;
    }
};
