import {Gif} from "../types/types";
import AssAnimation from "../img/ass.json";
import PopCornAnimation from "../img/popcorn.json";
import HeartAnimation from "../img/heart.json";
import _DUCK14_MONEY_OUT from '../img/_DUCK14_MONEY_OUT.json'
import dina from '../img/dina.json'
import peach from '../img/peach.json'
import R_Rainbow from '../img/R_Rainbow.json'
import Render from '../img/Render.json'
import what from '../img/what.json'
import frog from '../img/wrog.json'

export const gifs: Gif[] = [
    { id: 1, animationData: AssAnimation },
    { id: 2, animationData: PopCornAnimation },
    { id: 3, animationData: HeartAnimation },
    { id: 4, animationData: _DUCK14_MONEY_OUT },
    { id: 5, animationData: dina },
    { id: 6, animationData: peach},
    { id: 7, animationData: R_Rainbow },
    { id: 8, animationData: Render },
    { id: 9, animationData: what },
    { id: 10, animationData: frog },
];
