import { ClientType } from "../types/types";
import React from "react";

export const handleForClient = (
    forClient: any,
    clientData: ClientType,
    setClient: React.Dispatch<React.SetStateAction<boolean>>,
    setClientData: React.Dispatch<React.SetStateAction<ClientType>>,
    setSetting: React.Dispatch<React.SetStateAction<boolean>>
) => {
    if (forClient && forClient !== clientData) {
        if (forClient.status === false) {
            setClient(false);
        } else {
            setClient(true);
            setClientData(forClient);
            setSetting(false);
        }
    }
};
