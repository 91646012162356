import React, {useEffect, useState} from "react";
import {useLanguage} from "../context/LanguageContext";
import {useDataContext} from "../context/DataContext";
import {socketSend} from "../socket/socket";
import {UserAuth} from "../types/types";

export const TestAlert = () => {
    const {translations} = useLanguage()
    const {userData} = useDataContext()
    const token = localStorage.getItem('userToken');

    const [userInfo, setUserInfo] = useState<UserAuth>()

    useEffect(() => {
        if (token && userData)
        setUserInfo({token: token, wallet: userData.wallet})
    }, [token, userData]);


    const testAlert = () => {
        if (userInfo)
        socketSend('testAlert', userInfo )
            .then(() => {})
            .catch(() => {});
    };

    return (
        <button className={'button'}
                onClick={() => {testAlert()}}>{translations["button-send-test"]}
        </button>
    )
}
