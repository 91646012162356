import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {useDataContext} from "../../../context/DataContext";
import {useLanguage} from "../../../context/LanguageContext";

export const InputsFonts = () => {
    const {userData, setUserData} = useDataContext();
    const { translations} = useLanguage()
    const [isOpenTitle, setIsOpenTitle] = useState<boolean>(false);
    const [isOpenMessage, setIsOpenMessage] = useState<boolean>(false);
    const titleRef = useRef<HTMLInputElement>(null);
    const messageRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsOpenTitle(false);
                setIsOpenMessage(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const colorChange = (field: 'colorLeft' | 'colorRight' | 'colorFontDonate' | 'colorFontMessage') => (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setUserData({...userData, [field]: inputValue});
    };

    const handleFontChange = (field: 'fontDonate' | 'fontMessage', font: string) => {
        setUserData({...userData, [field]: font});
        if (field === 'fontDonate') {
            setIsOpenTitle(false);
        } else if (field === 'fontMessage') {
            setIsOpenMessage(false);
        }
    };

    return (
        <div className={'font-inputs'} ref={containerRef}>
            <div className="user-input font-title" style={{backgroundColor: isOpenTitle ? '#323232' : ''}}>
                <label className="label" htmlFor="temp2">{translations.setting.design.label1}</label>
                <input
                    className={'text-input'}
                    onClick={() => setIsOpenTitle(!isOpenTitle)}
                    type={'text'}
                    value={userData.fontDonate}
                    readOnly={true}
                    ref={titleRef}
                />
                {isOpenTitle && (
                    <div className="column-container">
                        {['Arial', 'Gotham Pro', 'Roboto', 'Poppins', 'Ruslan Display'].map((font) => (
                            <span
                                key={font}
                                style={{fontFamily: `${font}, sans-serif`}}
                                className={`select-font ${userData.fontDonate === font ? 'selected' : ''}`}
                                onClick={() => handleFontChange('fontDonate', font)}> {font} </span>
                        ))}
                    </div>
                )}
                <input
                    id="colorFontDonate"
                    className="input-color"
                    type="color"
                    value={userData.colorFontDonate}
                    onChange={colorChange("colorFontDonate")}
                />
            </div>

            <div className="user-input font-message">
                <label className="label" htmlFor="temp2">{translations.setting.design.label2}</label>
                <input
                    className={'text-input'}
                    onClick={() => setIsOpenMessage(!isOpenMessage)}
                    type={'text'}
                    readOnly={true}
                    value={userData.fontMessage}
                    ref={messageRef}
                />
                {isOpenMessage && (
                    <div className="column-container">
                        {['Arial', 'Gotham Pro', 'Roboto', 'Poppins', 'Ruslan Display'].map((font) => (
                            <span
                                key={font}
                                style={{fontFamily: `${font}, sans-serif`}}
                                className={`select-font ${userData.fontMessage === font ? 'selected' : ''}`}
                                onClick={() => handleFontChange('fontMessage', font)}> {font} </span>
                        ))}
                    </div>
                )}
                <input
                    id="colorFontMessage"
                    className="input-color"
                    type="color"
                    value={userData.colorFontMessage}
                    onChange={colorChange("colorFontMessage")}
                />
            </div>
        </div>
    );
};
