import React from "react";
import {useDataContext} from "../../context/DataContext";
import {useLanguage} from "../../context/LanguageContext";

export const InputMessage = () => {
    const { messageData, setMessageData} = useDataContext()
    const {translations} = useLanguage()

    const change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputValue = event.target.value;
        setMessageData({...messageData, text: inputValue})
    }

    return (
        <div className="message-input">
             <textarea id="messageSetting"
                       className="text-area"
                       autoComplete="off"
                       required
                       maxLength={200}
                       onChange={change}/>
            <label htmlFor={'messageSetting'} className={'label'}>{translations.client.message}</label>
            <p className={'value-length'}>{messageData.text.length} / 200</p>
        </div>
    )
}
