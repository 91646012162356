import React from "react";
import "../styles/setting.css";
import '../styles/design.css';
import '../styles/buttons.css'
import '../styles/containers.css'

import { SwitchSettings } from "../components/switchSettings";
import { Design } from "../components/design/design";
import { StreamSetup } from "../components/streamSetup/streamSetup";
import { Advanced } from "../components/advanced/advanced";
import { Donations } from "../components/donations/donations";
import { TabProvider, useTabContext } from "../context/TabContext";
import {useLanguage} from "../context/LanguageContext";

export const Setting = () => {
    const {translations} = useLanguage()

    return (
        <TabProvider>
            <div id={'donateAppBody'} className="donate-app-body">
                <div className={'settings'}>
                    <div className={'switch-set'}>
                        <h1>{translations["donation-settings"]}</h1>
                        <SwitchSettings />
                        <ContentTabs />
                    </div>
                </div>
                <Donations />
            </div>
        </TabProvider>

    );
};

const ContentTabs = () => {
    const { activeTab } = useTabContext();
    return (
        <>
            {activeTab === 'streamSetup' && <StreamSetup />}
            {activeTab === 'design' && <Design />}
            {activeTab === 'advanced' && <Advanced />}
        </>
    );
};
