import {TonConnectUI} from "@tonconnect/ui";

export const handlePayload = (payload: any, tonConnectUI: TonConnectUI) => {
    if (!payload) {
        tonConnectUI.setConnectRequestParameters(null);
    } else {
        tonConnectUI.setConnectRequestParameters({
            state: 'ready',
            value: {
                tonProof: payload,
            },
        });
    }
};
