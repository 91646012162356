import left from "../../../img/ic-left.svg";
import Lottie from "lottie-react";
import {gifs} from "../../../const/gifs";
import right from "../../../img/ic-right.svg";
import React from "react";
import {useDataContext} from "../../../context/DataContext";

export const InputGif = () => {
    const {userData, setUserData} = useDataContext();
    const stickerRight = () => {
        const newIndex = (userData.animated + 1) % gifs.length;
        setUserData({...userData, animated: newIndex})
    };

    const stickerLeft = () => {
        const newIndex = (userData.animated - 1 + gifs.length) % gifs.length;
        setUserData({...userData, animated: newIndex})
    };

    const fontDonateStyle = {
        color: userData.colorFontDonate,
        fontFamily: userData.fontDonate,
    };

    const fontMessageStyle = {
        color: userData.colorFontMessage,
        fontFamily: userData.fontMessage,
    };

    return (
        <div className={'view-select-sticker'}>
            <div className={'icon-input left-sticker'} onClick={stickerLeft}>
                <img className={'icon'} src={left} alt="left"/>
            </div>
            <div className={'stickers-input fix-stroke'}>
                <div className={'sticker'}>
                    <Lottie animationData={gifs[userData.animated].animationData} loop={true} className={'sticker'}/>
                </div>
                <div className={'title'}>
                    <div id="name-donate" className="display name-obs" style={fontDonateStyle}>Anonymous</div>
                    <div id="value-donate" className="display name-obs" style={fontDonateStyle}>10 ton</div>
                </div>
                <div id="message-donate" className="display text-obs" style={fontMessageStyle}>Message</div>
            </div>
            <div className={'icon-input right-sticker'} onClick={stickerRight}>
                <img className={'icon'} src={right} alt="right"/>
            </div>
        </div>
    )
}
