import addPhoto from "../../img/ic-photo.svg";
import React, {useEffect, useState} from "react";
import {useDataContext} from "../../context/DataContext";
import {url} from "../../const/const";

export const ImgUpload = () => {
    const {userData, setUserData} = useDataContext()
    const token = localStorage.getItem('userToken');
    const [imgExists, setImgExists] = useState(false);
    const [img, setImg] = useState<string>("");

    const MAX_FILE_SIZE = 1024 * 1024;

    const uploadImageToServer =  async (base64Image: string) => {
        const formImg = new FormData();
        if (token) {
            formImg.append('image', base64Image);
            formImg.append('token', token);
            formImg.append('wallet', userData.wallet);
        }
        try {
            const response = await fetch(`${url}/upload`, {
                method: 'POST',
                body: formImg
            });

            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
            console.log('Image uploaded successfully');
            setUserData({...userData, image: `upload/${userData._id}.png?${new Date().getTime()}`});
            console.log(userData)
            // window.location.reload()
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                alert('The file size exceeds the maximum limit of 1MB.');
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result as string;
                uploadImageToServer(base64Image);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (userData.image) {
            const img = new Image();
            img.src = `${url}/${userData.image}?${new Date().getTime()}`;
            img.onload = () => {
                setImgExists(true);
                setImg(img.src)
            }
            img.onerror = () => setImgExists(false);
        } else {
            setImgExists(false);
        }
    }, [userData.image]);


    return (
        <div className="avatar-uploader">
            <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                id="upload-input"
                style={{display: 'none'}}
            />
            <img src={addPhoto}
                 alt={'add'}
                 style={{width: '32px', left: '24px',top: '24px', position: 'absolute'}}
                 onClick={() => document.getElementById('upload-input')?.click()}/>
            {imgExists && (
                <img
                    id="upload-input"
                    src={img}
                    alt="Avatar"
                    className="avatar-image"
                    onClick={() => document.getElementById('upload-input')?.click()}
                />
            )}


            {imgExists && (


                <img
                    src={addPhoto}
                    alt={'user-photo'}
                    className={'ic-add-photo'}
                    onClick={() => document.getElementById('upload-input')?.click()}
                />
            )}

        </div>
    )
}
