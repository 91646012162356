import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { Buffer } from 'buffer';

(window as any).Buffer = Buffer;

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
