export function formatDate(createdAt: string | Date, locale: string = 'en-US'): string {
    const date = new Date(createdAt);
    const now = new Date();

    const isToday = now.toDateString() === date.toDateString();

    const optionsTime: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    const optionsDate: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };

    if (isToday) {
        return date.toLocaleTimeString(locale, optionsTime);
    } else {
        const time = date.toLocaleTimeString(locale, optionsTime);
        const dayMonth = date.toLocaleDateString(locale, optionsDate);
        return `${time}, ${dayMonth}`;
    }
}
