import './changeCurrency.css'
import mrdn from '../../../img/mrdn.webp';
import dogs from '../../../img/dogs.webp';
import not from '../../../img/not.webp';
import usdt from '../../../img/usdt.webp';
import ton from '../../../img/ton.png';
import menu from '../../../img/ic-arrow.svg';
import { useState, useEffect, useRef } from "react";
import {useDataContext} from "../../../context/DataContext";
import {useLanguage} from "../../../context/LanguageContext";

export interface ChangeCurrencyProps {
    onCurrencyChange: (currency: string) => void;
}

export const ChangeCurrency = ({ onCurrencyChange }: ChangeCurrencyProps) => {
    const currencies = [
        { name: 'TON', img: ton},
        { name: 'usdt', img: usdt },
        { name: 'not', img: not },
        { name: 'dogs', img: dogs },
        { name: 'mrdn', img: mrdn }
    ];
    const {clientData} = useDataContext()
    const {translations} = useLanguage()

    const [open, setOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);
    const ref = useRef<HTMLDivElement>(null);

    const handleCurrencyChange = (curr: { name: string, img: string }) => {
        setSelectedCurrency(curr);
        onCurrencyChange(curr.name);
        setOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div className="coins" ref={ref}>
            <div className={'current-currency'} onClick={() => setOpen(!open)}>
                <img src={selectedCurrency.img} alt={selectedCurrency.name} className={'currency-img'} />
                <h3>{selectedCurrency.name}</h3>
                <img src={menu} alt={'arr'} />
            </div>
            <label>{translations.client["min-amount"]} {clientData.minDonate} USD</label>
            {open && (
                <div className="select-currency">
                    {currencies.map(({ name, img }) => (
                        <span
                            key={name}
                            onClick={() => handleCurrencyChange({ name, img })}
                            className="currency"
                        >
                            {img && <img src={img} alt={name} className="currency-img" />}
                            {name}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};
