export function amountFormatter(value: string){
    let result = value.replace(/[^0-9.,]/g, '').replace(/,/g, '.');

    const dotIndex = result.indexOf('.');
    if (dotIndex !== -1) {
        const decimalPart = result
            .slice(dotIndex + 1)
            .replace(/\./g, '')
            .slice(0, 3);

        result = result.substr(0, dotIndex + 1) + decimalPart;
    }

    if (result === '.' || result === '00') return '0.';

    if (result[0] === '0') {
        if (result[1] === '0') {
            result = result.replace(/^0+/, '');
            result = `0${result}`;
        } else if (result[1] && result[1] !== '.') {
            if (dotIndex === -1) {
                result = `0.${result.slice(1, 3)}`;
            } else {
                result = result.replace(/^0+/, '');
            }
        }
    }

    return result;
}
