import React, {useEffect, useState} from "react";
import '../../styles/donations.css';
import { socketSend } from "../../socket/socket";
import Lottie from "lottie-react";
import Search from '../../img/srch.json';
import {CommentType} from "../../types/types";
import fire from '../../img/ic-fire.svg'
import {getTonPrice, replaceAmount} from "../../utils/utils";
import {useDataContext} from "../../context/DataContext";
import {url} from "../../const/const";
import {useLanguage} from "../../context/LanguageContext";
import {formatDate} from "./date";


export const Donations = () => {
    const {meCommentsData, myCommentsData, userData} = useDataContext()
    const [tonRate, settonRate] = useState<number>(1)
    const { translations} = useLanguage()
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [viewComments, setViewComments] = useState<CommentType[]>([]);

    const fetchPrice = async () => {
        const tonPrice = await getTonPrice();
        settonRate(tonPrice.TON.prices.USD)
    }



    useEffect(() => {
        const sourceComments = isChecked ? myCommentsData : meCommentsData;
        fetchPrice()

        const uniqueComments = sourceComments.filter((comment, index, self) =>
            index === self.findIndex((c) => c._id === comment._id)
        );
        setViewComments(uniqueComments);
    }, [meCommentsData, isChecked, myCommentsData]);

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };


    useEffect(() => {

        const handleScroll = async () => {

            if (loading) {
                return;
            }
            const {innerHeight, scrollY} = window;
            const {scrollHeight} = document.body;


            if (innerHeight + scrollY >= scrollHeight) {
                console.log('send')
                setLoading(true);
                if (viewComments.length > 0){
                    await socketSend('load', {
                        token: localStorage.getItem('userToken'),
                        wallet: userData.wallet,
                        modeComment: isChecked,
                        id: viewComments[viewComments.length - 1]._id
                    });
                }

                setLoading(false);
            }
        };


        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [loading, isChecked, viewComments]);


    return (
        <div className='donations '>
            <div className={'donations-button'}>
                <label className="switch-wrapper">
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleSwitchChange}
                    />
                    <span className="switch-background">
                        <div className='tabs-donations'>
                            <h3 className={!isChecked ? 'active' : ''}>{translations.donations["button-me"]}</h3>
                            <h3 className={isChecked ? 'active' : ''}>{translations.donations["button-my"]}</h3>
                        </div>
                        <span className="switch-knob"></span>
                    </span>
                </label>
            </div>
            <div className='donations-comments'>
                <div className='comments'>
                    {viewComments.length > 0 ? viewComments.map((comment) => (
                        <div key={comment._id} className='comment fade-in-element'>
                            <div className='image-user'>
                                <img
                                    src={`${url}/upload/${!isChecked ? comment.clientId : comment.uid}.png?${new Date().getTime()}`}
                                    alt='img'
                                    className='image-uid'
                                    onError={(e) => {
                                        e.currentTarget.style.display = 'none';
                                        const emojiElement = e.currentTarget.nextElementSibling as HTMLElement;
                                        if (emojiElement) {
                                            emojiElement.style.display = 'inline';
                                        }
                                    }}
                                />
                                <img src={fire} alt={'🔥'} className='emoji' style={{display: 'none'}}>
                                </img>

                            </div>
                            <div className='message'>
                                <div className='name-message'>
                                    <h2>{!isChecked ? comment.nameClient : comment.name}</h2>
                                    <div className='text-comment'>
                                        <p>{comment.text}</p>
                                        <p style={{
                                            color: '#686868',
                                            fontWeight: '700',
                                            fontSize: '10px',
                                            lineHeight: '12px'
                                        }}>
                                            {formatDate(comment.createdAt, 'en-US')}
                                        </p>
                                    </div>
                                </div>
                                <div className='date-amount'>
                                    <h2>{comment.amount} ton</h2>
                                    <p>~ ${replaceAmount(comment.amount * tonRate)}</p>
                                </div>
                            </div>
                        </div>
                    )) : (
                        <div className='none-comments'>
                            <Lottie
                                animationData={Search}
                                loop={true}
                                className='search'
                            />
                            <h1>No donations yet</h1>
                            <h2>{!isChecked ? translations.donations["text-me"] : translations.donations["text-my"]}</h2>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
