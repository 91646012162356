import send from "../../../img/ic-send.svg";
import React, {useCallback, useEffect, useState} from "react";
import {useDataContext} from "../../../context/DataContext";
import {Address, toNano} from "@ton/core";
import {bodyTx} from "./bodyTx";
import {socketSend} from "../../../socket/socket";
import {useTonAddress, useTonConnectModal, useTonConnectUI} from "@tonconnect/ui-react";
import {convertToCoin, replaceAmount} from "../../../utils/utils";
import {useLanguage} from "../../../context/LanguageContext";

export const ButtonPay = ({selectCurrency} : {selectCurrency: string}) => {

    const {messageData, clientData, userData, walletsClient, coinRate} = useDataContext()
    const {translations} = useLanguage()
    const [userWallet, setUserWallet] = useState<string | null>('')

    useEffect(() => {
        const wallet = walletsClient[selectCurrency] || null
        setUserWallet(wallet)
    }, [selectCurrency, walletsClient, userData.wallet]);

    const [tonConnectUI] = useTonConnectUI();
    const tonAddress = useTonAddress(true);
    const { open } = useTonConnectModal();

    tonConnectUI.uiOptions = {
        actionsConfiguration: {
            returnStrategy: 'back'
        }
    };

    const pay = useCallback(async () => {
        if (!!tonAddress && messageData.amount * coinRate >= clientData.minDonate) {
            try {
                const res = await tonConnectUI.sendTransaction({
                    validUntil: Math.floor(Date.now() / 1000) + 360,
                    messages: [
                        {
                            address: Address.parse(`${userWallet || clientData.wallet}`).toString({bounceable: false}),
                            amount: toNano(userWallet? 0.05 : replaceAmount(messageData.amount * 0.95)).toString(),
                            payload: bodyTx(clientData.wallet, userData.wallet, `${ selectCurrency === 'usdt' ? ((messageData.amount * 0.95)/1000).toFixed(6) : replaceAmount(messageData.amount * 0.95) }`)
                        },
                        {
                            address: Address.parse(`${userWallet || clientData.wallet}`).toString({bounceable: false}),
                            amount: toNano(userWallet? 0.05 : replaceAmount(messageData.amount * 0.05)).toString(),
                            payload: bodyTx('UQBJ612eiQD58Jz4MPJ7oaVryO61swz_2NzDHLtqDBYtYaon', userData.wallet, `${ selectCurrency === 'usdt' ? ((messageData.amount * 0.05)/1000).toFixed(6): replaceAmount(messageData.amount * 0.05) }`)
                        }
                    ]
                });

                socketSend('comment', {...messageData, boc: res.boc, amount: replaceAmount(messageData.amount * coinRate) })
                    .then(() => { })
                    .catch(() => { });
            } catch (e) {
                console.log('cancel', e);
            }
        } else {
            open();
        }

    }, [tonAddress, tonConnectUI, clientData.wallet, messageData.amount, open]);

    return (
        <div className="pay">
            <button className="button-pay"  onClick={pay}>
                <img className={'icon-send'} src={send} alt="Copy"/>
                {translations.client.button} {messageData.amount} {selectCurrency}
            </button>
        </div>
    )
}



